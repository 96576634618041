<template>
  <div>
    <nav-bar></nav-bar>
    <div class="banner">
      <div class="content wow bounceIn">
        <div class="title">郑州约克计算机技术有限公司</div>
        <div class="describe flex justify-around">
          <div>新格局</div>
          <div class="flex"><span></span></div>
          <div>新模式</div>
          <div class="flex"><span></span></div>
          <div>新时代</div>
        </div>
      </div>
    </div>
    <section>
      <div>
        <div class="about flex">
          <img src="../assets/images/index/introduce.png" alt="" />
          <div class="flex flex-sub">
            <div class="wow fadeInRight">
              <div class="title">关于我们</div>
              <div class="h"></div>
              <div class="describe">
                郑州约克计算机技术有限公司成立于2007年9月，是一家集软件开发，硬件开发，销售服务于一体的多元化软硬件开发企业。目前公司主营软件开发，系统集成，物联网开发，传感器定制等。
              </div>
            </div>
          </div>
        </div>
        <div class="about flex">
          <div class="flex flex-sub">
            <div class="wow fadeInLeft">
              <div class="title">联系我们</div>
              <div class="h"></div>
              <div class="describe">
                <div>
                  <span>联系电话<i></i></span>：13903868733
                </div>
                <div>
                  <span>邮箱<i></i></span>：zzutligang@126.com
                </div>
                <div>
                  <span>联系QQ<i></i></span>：2112297540
                </div>
                <div>
                  <!--
                  <span>联系地址<i></i></span>
                  <div class="flex-sub">：郑州市金水区汇泽时代广场</div>
                  -->
                </div>
              </div>
            </div>
          </div>
          <div class="map" id="container"></div>
        </div>
      </div>
    </section>
    <footbar></footbar>
  </div>
</template>
<script>
// import maps from "qqmap";
import NavBar from "@/components/nav/index";
import footbar from "@/components/footbar/index";
import { WOW } from "wowjs";
export default {
  name: "about",
  components: { NavBar, footbar },
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      var wow = new WOW({
        live: false,
      });
      wow.init();
    });
    //this.mapLoad(); //不再调用地址定位
  },
  methods: {
    mapLoad() {
      /* eslint-disable */
      var center = new TMap.LatLng(34.817996, 113.647819);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      var map = new TMap.Map(document.getElementById("container"), {
        center: center, //设置地图中心点坐标
        zoom: 16, //设置地图缩放级别
      });
      //初始化marker
      var marker = new TMap.MultiMarker({
        id: "marker-layer", //图层id
        map: map,
        styles: {
          //点标注的相关样式
          marker: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png",
          }),
        },
        geometries: [
          {
            //点标注数据数组
            id: "demo",
            styleId: "marker",
            position: new TMap.LatLng(34.817996, 113.647819),
            properties: {
              title: "marker",
            },
          },
        ],
      });
      //初始化label
      var label = new TMap.MultiLabel({
        id: "label-layer",
        map: map,
        styles: {
          label: new TMap.LabelStyle({
            color: "#3777FF", //颜色属性
            size: 16, //文字大小属性
            offset: { x: 0, y: -40 }, //文字偏移属性单位为像素
            angle: 0, //文字旋转属性
            alignment: "center", //文字水平对齐属性
            verticalAlignment: "middle", //文字垂直对齐属性
          }),
        },
        geometries: [
          {
            id: "label", //点图形数据的标志信息
            styleId: "label", //样式id
            position: center, //标注点位置
            content: "汇泽时代广场", //标注文本
            properties: {
              //标注点的属性数据
              title: "label",
            },
          },
        ],
      });
      /* eslint-enable */
      return;
      // var that = this;
      // maps.init("CKMBZ-COQH4-G3YUS-XERFX-AGNLF-BWBUT", () => {
      //   var myLatlng = new maps.LatLng(34.78562, 113.69234);
      //   var myOptions = {
      //     zoom: 15,
      //     center: myLatlng,
      //   };

      //   var label = new maps.Label({
      //     position: myLatlng,
      //     content: "文本标注",
      //   });
      //   that.map = new maps.Map(
      //     document.getElementById("container"),
      //     myOptions,
      //     label
      //   );
      // });
    },
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 444px;
  background-color: transparent;
  background-image: url("../assets/images/banner/about.png");
  /* background-size: 100% 100%; */
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  display: table;
}
.banner .content {
  width: 1200px;
  display: table-cell;
  height: 100%;
  position: relative;
  z-index: 1;
  text-align: center;
}
.banner .content .title {
  font-size: 50px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 130px;
}
.banner .content .describe {
  width: 365px;
  height: 60px;
  padding: 0 63px;
  line-height: 60px;
  border: 1px solid #ffffff;
  border-radius: 30px;
  font-size: 22px;
  color: #fefefe;
  margin: 50px auto 0;
}
.banner .content .describe > div {
  width: 73px;
  text-align: center;
}
.banner .content .describe > div span {
  display: inline-block;
  width: 5px;
  height: 5px;
  background: #ffffff;
  border-radius: 50%;
  margin: auto;
}
section {
  background: #ffffff;
  padding: 61px 0 0;
}
section > div {
  width: 1200px;
  margin: 0 auto;
}
section > div .about {
  margin-bottom: 100px;
}
section > div .about img {
  width: 715px;
  height: 477px;
}
section > div .about .map {
  width: 715px;
  height: 477px;
}
section > div .about .flex > div {
  margin: auto;
  width: 300px;
  padding: 30px 38px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 7px 33px 2px rgba(0, 0, 0, 0.06);
}
section > div .about .title {
  font-size: 24px;
  font-weight: 500;
  color: #666666;
}
section > div .about .h {
  width: 56px;
  height: 3px;
  background: #02b2b5;
  margin: 14px 0;
}
section > div .about .describe {
  width: 293px;
  height: 119px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #737373;
  line-height: 26px;
}
section > div .about .describe > div {
  height: 30px;
  display: flex;
  position: relative;
}
section > div .about .describe > div > div {
  position: absolute;
  top: 0;
  left: 0;
  text-indent: 80px;
}
section > div .about .describe span {
  display: inline-block;
  width: 80px;
  text-align: justify;
}
section > div .about .describe span i {
  display: inline-block;
  width: 100%;
}
</style>
